@import "~bootstrap/scss/bootstrap";
.row {
    margin: 0px;
}

body {
    background-color: #f9fafb;
    overflow-y: auto !important;
    width: 100vw;
}

.grayText {
    color: rgb(92, 92, 92);
}

.textAlignStart {
    text-align: start;
}

// shared form input fields styling
.btn {
    height: auto;
}

label {
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    width: 100%;
}

.width100 {
    width: 100%;
}

.authForms {
    margin-bottom: 44px;
}

.formsCard {
    margin-bottom: 50px;
    border: none;
}

.submitButton {
    width: 60%;
    .submitButtonSpinner {
        vertical-align: middle;
    }
    .submitButtonLabel {
        color: white;
    }
}

.loadSpinner {
    color: white;
}

.form-group {
    margin-bottom: 1rem;
}

.btn-link {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.card {
    border: none;
}

.mrgBottom50 {
    margin-bottom: 50px;
}

// home & landing styling
.logo {
    max-width: 40%;
    height: auto;
}

.mainTitle {
    line-height: 180%;
}

// what's app floating icon style
.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #fff;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #eee;
    z-index: 100;
    margin-bottom: 16px;
}

.my-float {
    margin-top: 16px;
}

.fa-whatsapp:before {
    content: "\f232";
    color: white;
}

.nav-link {
    max-height: 40px;
    display: flex;
    align-items: center;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

a:hover {
    text-decoration: none
}